import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { 
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
  GET_ALL_MESSAGES_OF_ORDER,
  SEND_MESSAGE,
  MARK_NOTIFICATIONS_AS_READ,
} from '/@/gql';
import { FileType, OrderViewPage, useFiles, UserRole, getValideFiles } from '@jasper/shared';
import { UseAuth } from '/@/contexts';
import { useEffect, useState } from 'react';
import {io} from "socket.io-client";
import { getWebsocketApiUrl, getApiUrl } from '../../config';
import { GET_FILES_DOWNLOAD_URLS } from '@jasper/shared/gql/files';
const __TAKE__ = 5;

const OrderView = () => {

  const { order_id } = useParams();
  const { user } = UseAuth();
  const navigate = useNavigate();

  if(!order_id) {
    return
  }

  const { data, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id:  order_id
        }
      },
      fetchPolicy: 'cache-first',
    }
  );

  const {getFileUrl, uploadFileToS3} = useFiles(order_id);

  const [message, setMessage] = useState('');
  const [scrollBottom, setScrollBottom] = useState(true);
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const [page, setPage] = useState(0);
  const [messagesToShow, setMessageToShow] = useState<any[]>([])
  const [newMessage, setNewMessage] = useState<any>()
  const [hasMore, setHasMore] = useState<boolean>(false)
  
  const getFilesWithPath = async(messages) => {
    return await Promise.all(
      messages.map(async (msg) => {
        const updatedFiles = await Promise.all(
          (msg.files ?? []).map(async (file) => {
            try {
              return {
                id: file.id,
                path: await getFileUrl(file.key),
                pathCompressed: await getFileUrl(file.keyCompressed ?? file.key),
                key: file.key,
                keyCompressed: file.keyCompressed,
                fileType: file.fileType,
              };
            } catch (error) {
              console.error(`Error fetching path for file key: ${file.key}`, error);
              return { key: file.key, fileType: file.fileType, path: null };
            }
          })
        );
        return { ...msg, files: updatedFiles };
      })
    );
  };
  
  const { loading } = useQuery(
    GET_ALL_MESSAGES_OF_ORDER,
    {
      variables: {
        take: __TAKE__,
        skip: __TAKE__ * page,
        where: {
          orderId: {
            equals: order_id,
          },
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        markMessagesAsRead()
        if (data?.getAllChatsOfOrder?.count) {
          const newMessages = data?.getAllChatsOfOrder.notification ?? []; 
          getFilesWithPath(newMessages).then((processedMessages) => {
            if (processedMessages.length === newMessages.length) {
              setMessageToShow((prev) => [...(processedMessages.toReversed()), ...prev]);
              setScrollBottom(false)
      
              if ([...(processedMessages), ...messagesToShow].length === data?.getAllChatsOfOrder.count) {
                setHasMore(false);
              }
              else {
                setHasMore(true);
              }
            }
          });
        } else {                  
          setHasMore(false);
        }
      }
    }
  );

  const fetchMoreMessages = () => {
    if (hasMore) {
      setPage(page + 1)
    }
  };
  const [send] = useMutation(SEND_MESSAGE);
  const [updateMessages] = useMutation(MARK_NOTIFICATIONS_AS_READ);
  const markMessagesAsRead = () => {
    updateMessages(
      {
        variables: {
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: order_id
            },
            userGroupId: {
              not: {
                equals: user?.userGroupId
              }
            }
          }
        }
      }
    )
  }

  const sendMessage = async () => {
    if(filesToSend.length !== 0) { 
      uploadFileToS3(filesToSend, FileType.CHAT_FILE).then(async(data) => {
        if(data && data.length !== 0 ) {
          await send(
            {
              variables: {
                args: {
                  createdAt: new Date(),
                  order: {
                    connect: {
                      id: order_id
                    }
                  },
                  updatedAt: new Date(),
                  userGroup: {
                    connect: {
                      id: user.userGroupId
                    }
                  },
                  message: message,
                  files:{
                    create: data
                  }
                },
              }
            }
          )
        }
        setFilesToSend([])
      })
    }
    else {
      await send(
        {
          variables: {
            args: {
              createdAt: new Date(),
              order: {
                connect: {
                  id: order_id
                }
              },
              updatedAt: new Date(),
              userGroup: {
                connect: {
                  id: user.userGroupId
                }
              },
              message: message,
            },
          }
        }
      )
    }
  }
  const [orderComments, setOrderComments] = useState<any[]>([]);

  const [fileWithPath, setFileWithPath] = useState([]);
  const [getFilesDownloadUrls, { data: filesWithPathB }] = useLazyQuery(GET_FILES_DOWNLOAD_URLS, {
    onCompleted: (value) => {
      setFileWithPath(value?.getSignedDownloadUrlsFromKeys?.files || []);
    },
  });

  useEffect(() => {
    if (data && user.role === UserRole.USER && data?.getOrderByUniqueAttribute?.provider?.id !== user.userGroupId) {
      navigate('/chats?page=0')
    }
    if (data?.getOrderByUniqueAttribute?.files.length > 0) {
      const validFiles = getValideFiles(data?.getOrderByUniqueAttribute?.files ?? []);
      getFilesDownloadUrls({ variables: { args: {files :[ ...validFiles ]} } });
    }
  }, [data]);

  useEffect(() => {
    const socket = io(getApiUrl(),
      {
        query: {
          type: 'chat',
          user_group_id: user?.userGroupId,
          order_id: order_id
        },
      });
    const socketNotif = io(getApiUrl(),
      {
        query: {
          type: 'notification'
        },
      });
    socketNotif.on('notification', () => {
      refetch()
    })

    socket.on('chat', (data) => {
      if(data.files.length > 0){
        getFilesWithPath([data]).then((msg)=> {
          setNewMessage(msg[0])
          setScrollBottom(true)
        })
      }
      else {
        data.files = []
        setNewMessage(data)
        setScrollBottom(true)
      }
    })

    socket.on("disconnect", (reason) => {
      console.info("disconnect", reason, user.id);
      socket.connect();
    });
    return () => {
      socket.disconnect()
      socketNotif.disconnect()
      socketNotif.off('notification')
      socketNotif.off('disconnect')
      socket.off('chat')
    }
  }, [])

  useEffect(()=>{
    if(newMessage) {
      setMessageToShow([...messagesToShow, newMessage])
    }
  }, [newMessage])

  useEffect(()=>{
    if(data?.getOrderByUniqueAttribute) {
      getFilesWithPath(data
        .getOrderByUniqueAttribute
        .orderComment
      ).then((orderCommentsWithUpdatedFilesWithFilePath) => {
        setOrderComments(orderCommentsWithUpdatedFilesWithFilePath);
      })
    }
  }, [data])

  return (
    <OrderViewPage
      orderComments={orderComments}
      fileWithPath={fileWithPath ?? []}
      order={data?.getOrderByUniqueAttribute}
      user={user}
      refetch={refetch}
      setMessage={setMessage}
      messages={[...messagesToShow]}
      sendMessage={sendMessage}
      filesToSend={filesToSend}
      setFilesToSend={(files: File[]) => setFilesToSend(files)}
      loading={loading}
      hasMore={hasMore} 
      fetchMoreMessages={fetchMoreMessages}
      scrollBottom={scrollBottom}
      refetchFiles={getFilesDownloadUrls}
    />
  )
};


export default OrderView;
