import {
  ItemType,
  ModelTypenames,
  ArchType,
  ProductPreferenceType,
  ArchTypeTranslated,
  FileType,
  Order,
} from '../types/enums';
import { Product, AnatomyItem, RemovableItem, ImplantItem } from '../types/interfaces';
import { isArrayOfInt } from '../utils/others.utils';
import {
  formatChoicesData
} from "./item.utils";

export const getAnatomyItemChoiceWhere = (productType: ItemType) => {
  switch (productType) {
    case ItemType.LAY:
      return {
        lay: {
          equals: true,
        }
      }
    case ItemType.CROWN:
      return {
        crown: {
          equals: true,
        }
      }
    case ItemType.BRIDGE:
      return {
        bridge: {
          equals: true,
        }
      }
    case ItemType.FULL_DENTURE:
    case ItemType.IMMEDIATE_DENTURE:
    case ItemType.FULL_DENTURE_WAX_BITE:
      return {
        fullDenture: {
          equals: true,
        }
      }
    case ItemType.PARTIAL_DENTURE:
      return {
        partialDenture: {
          equals: true,
        }
      }
    case ItemType.VENEER:
      return {
        veneer: {
          equals: true,
        }
      }
    default:
      return {};
  }
};

export const isImplant = (order: Order): boolean => {
  return (order?.products ?? []).reduce((acc, product) => {
    if (
      product?.productType?.toUpperCase() === ItemType.IMPLANT
      || product?.productType?.toUpperCase() === ItemType.IMPLANT_CROWN
      || product?.productType?.toUpperCase() === ItemType.ABUTMENT
      || product?.productType?.toUpperCase() === ItemType.CUSTOM_ABUTMENT
      || product?.productType?.toUpperCase() === ItemType.SPLINTED_CROWN_IMPLANT
      || product?.productType?.toUpperCase() === ItemType.BRIDGE_IMPLANT
      || product?.productType?.toUpperCase() === ItemType.BRIDGE_PONTIC
      || (product?.implantItems ?? []).length > 0
    ){
      acc = true;
    }
    return (acc);
  }, false);
};

export const isOrderWithNoAttachement = (order: Order): boolean => {
  return (order?.files ?? []).reduce((acc, file) => {
    if (file?.fileType?.toLowerCase().replace("_", "") === FileType.ORDER_FILE?.toLowerCase().replace("_", "")) {
      acc = false;
    }
    return (acc);
  }, true);
};

export const isPAC = (order: Order) => {
  return (order?.products ?? []).find((product) => product.productType === ItemType.FULL_DENTURE)
}

export const isBridgeOnInlayCore = (
  products: {productType: ItemType; teeth?: number[]}[],
  actualProduct?: {productType: ItemType; teeth?: number[]},
): boolean => {
  if (!products || products.length === 0) {
    return false;
  }
  const bridge = products.find(
    product => product.productType === ItemType.BRIDGE,
  );
  const inlayCore = products.find(
    product => product.productType === ItemType.INLAY_CORE,
  );
  if (!bridge || !inlayCore) {
    return false;
  }
  const bridgeItem = (bridge?.anatomyItem ?? []).find((item) => item.itemType === ItemType.BRIDGE);
  if (bridgeItem?.itemMaterial?.id){
    // if bridge workflow is already made and creation was made on two product (first the bridge and second the inlay-core)
    // we want to prevent from having the inlay-core workflow skipped
    return (false);
  }
  if (actualProduct) {
    const actualProductTeethIncluded = (actualProduct.teeth ?? []).every(
      teeth => ((bridge?.teeth ?? []).includes(teeth) ? true : false),
    );
    if (!actualProductTeethIncluded) {
      return false;
    }
  }
  return (inlayCore?.teeth ?? []).every(teeth => {
    if (!(bridge?.teeth ?? []).includes(teeth)) {
      return false;
    }
    return true;
  });
};

export const isBridgeAndInlayCoreOverlapping = (products: {productType: ItemType; teeth?: number[]}[]) => {
    let returnVal = false;
    const bridge = products.find(
      product => product.productType === ItemType.BRIDGE,
    );
    const inlayCore = products.find(
      product => product.productType === ItemType.INLAY_CORE,
    );
    if (!bridge || !inlayCore) {
      return false;
    }
    const bridgeTeeth = bridge.teeth as number[];
    const inlayCoreTeeth = inlayCore.teeth as number[];
    inlayCoreTeeth.forEach(tooth => {
      if(bridgeTeeth.includes(tooth)){
        returnVal = true;
      }
    });
    return returnVal;
};

export const getAllNonSplintedCrown = (teeths: number[]) => {
  const splintedCrowns: number[][] = getAllPossibleSplintedCrown(teeths);
  return teeths.filter((teeth: number) => {
    return !splintedCrowns.find((splintedCrownOption: number[]) =>
      splintedCrownOption.includes(teeth),
    );
  });
};

export const getAllPossibleSplintedCrown = (teeths: number[] = []) => {
  const output = [];
  if (!teeths) {
    return [];
  }

  teeths = [...teeths].sort((a, b) => a - b);
  let i = 0;
  while (i < teeths.length) {
    let x: number = i;
    const new_possibility: number[] = [];
    while (
      teeths[x] - teeths[x + 1] == -1 ||
      (teeths[x + 1] === 11 && teeths.includes(21)) ||
      (teeths[x + 1] === 21 && teeths.includes(11)) ||
      (teeths[x + 1] === 31 && teeths.includes(41)) ||
      (teeths[x + 1] === 41 && teeths.includes(31))
    ) {
      if (!new_possibility.includes(teeths[x])) {
        new_possibility.push(teeths[x]);
      }
      if (teeths[x + 1] && teeths[x] - teeths[x + 1] == -1) {
        new_possibility.push(teeths[x + 1]);
      }
      if (
        (teeths[x + 1] === 11 && teeths.includes(21)) ||
        (teeths[x + 1] === 21 && teeths.includes(11)) ||
        (teeths[x + 1] === 31 && teeths.includes(41)) ||
        (teeths[x + 1] === 41 && teeths.includes(31))
      ) {
        new_possibility.push(teeths[x + 1]);
      }
      x++;
    }
    i = x + 1;
    if (new_possibility.length > 1) {
      output.push(new_possibility);
    }
  }
  return output;
};

export const removeTeethFromBridgeOnInlayCore = (crownTeeths, products) => {
  const allBridges = products.filter((product) => product.productType === ItemType.BRIDGE) ?? [];
  allBridges.forEach((bridge) => {
    crownTeeths = crownTeeths.filter((teeth) => !bridge.teeth.includes(teeth));
  });
  return crownTeeths;
};

export const getAnatomyItemMaterialChoices = (
  anatomyItemChoicesData: { getItemMaterialsWhere: { id: string, inlayCore: string, crown: string }[] },
  itemType: ItemType,
) => {
  let filteredAnatomyItemChoicesData =
    anatomyItemChoicesData.getItemMaterialsWhere;

  if (itemType === ItemType.INLAY_CORE) {
    filteredAnatomyItemChoicesData = filteredAnatomyItemChoicesData.filter(
      m => m.crown || m.id === "METAL_NON_PRECIOUS",
    );
  }

  return formatChoicesData(filteredAnatomyItemChoicesData);
};

export const getItemTypeLabel = (
  productType: ItemType,
) => {
  if (productType === ItemType.FULL_DENTURE) {
    return "Prothèse amovible complète";
  } else if (productType === ItemType.IMMEDIATE_DENTURE) {
    return "Prothèse amovible immédiate";
  } else if (productType === ItemType.FULL_DENTURE_WAX_BITE) {
    return "Cire d'occlusion pour prothèse amovible complète";
  } else if (productType === ItemType.BLEACHING_TRAY) {
    return "Gouttière de blanchiment";
  } else if (productType === ItemType.RETAINER) {
    return "Gouttière de contention";
  } else if (productType === ItemType.NIGHT_GUARD) {
    return "Gouttière anti-bruxisme";
  } else if (productType === ItemType.CROWN) {
    return "Couronne";
  } else if (productType === ItemType.INLAY) {
    return "Inlay";
  } else if (productType === ItemType.ONLAY) {
    return "Onlay";
  } else if (productType === ItemType.OVERLAY) {
    return "Overlay";
  } else if (productType === ItemType.LAY) {
    return "Inlay/Onlay/Overlay";
  } else if (productType === ItemType.VENEER) {
    return "Facette";
  } else if (productType === ItemType.INLAY_CORE) {
    return "Inlay-core";
  } else if (
    productType === ItemType.POST_AND_CORE_RETAINED_CROWN
    || productType === ItemType.RICHMOND_CROWN
  ) {
    return "Couronne Richmond";
  } else if (productType === ItemType.BRIDGE) {
    return "Bridge";
  } else if (productType === ItemType.IMPLANT) {
    return "Pilier";
  } else if (productType === ItemType.IMPLANT_CROWN){
    return "Couronne sur implant";
  } else if (productType === ItemType.SPLINTED_CROWN_IMPLANT) {
    return "Couronne solidarisée sur implant";
  } else if (productType === ItemType.SPLINT) {
    return "Gouttière";
  } else if (productType === ItemType.PARTIAL_DENTURE) {
    return "Prothèse amovible partielle";
  } else if (productType === ItemType.FULL_DENTURE) {
    return "Prothèse amovible complète";
  } else if (productType === ItemType.BRIDGE_IMPLANT) {
    return "Bridge sur implant";
  } else if (productType === ItemType.ABUTMENT) {
    return "Couronne sur implant"
  } else if (productType === ItemType.SCAN_ONLY) {
    return "Numérisation uniquement"
  } else if (productType === ItemType.SPACE_MAINTAINER) {
    return "Mainteneurs d'espace";
  } else if (productType === ItemType.RETENTION_WIRE) {
    return "Fils de contention";
  } else if (productType === ItemType.WAX_UP) {
    return "Wax up";
  } else if (productType === ItemType.ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS) {
    return "Empreintes supplémentaires pour prothèse amovible";
  } else {
    return "non défini";
  }
};

export const isAnatomyItemType = (productType: ItemType) => {
  return (
    productType === ItemType.CROWN ||
    productType === ItemType.LAY ||
    productType === ItemType.INLAY ||
    productType === ItemType.ONLAY ||
    productType === ItemType.VENEER ||
    productType === ItemType.INLAY_CORE ||
    productType === ItemType.POST_AND_CORE_RETAINED_CROWN ||
    productType === ItemType.BRIDGE
  );
};

export const isImplantItemType = (productType: ItemType) => {
  return (
    productType === ItemType.IMPLANT ||
    productType === ItemType.ABUTMENT ||
    productType === ItemType.CUSTOM_ABUTMENT ||
    productType === ItemType.IMPLANT_CROWN ||
    productType === ItemType.SPLINTED_CROWN_IMPLANT ||
    productType === ItemType.BRIDGE_IMPLANT
  );
};

export const isRemovableItemType = (productType: ItemType) => {
  return (
    productType === ItemType.PARTIAL_DENTURE ||
    productType === ItemType.FULL_DENTURE ||
    productType === ItemType.IMMEDIATE_DENTURE ||
    productType === ItemType.FULL_DENTURE_WAX_BITE ||
    productType === ItemType.SPLINT ||
    productType === ItemType.BLEACHING_TRAY ||
    productType === ItemType.RETAINER ||
    productType === ItemType.NIGHT_GUARD ||
    productType === ItemType.RETENTION_WIRE ||
    productType === ItemType.SPACE_MAINTAINER ||
    productType === ItemType.ADDITIONAL_IMPRESSION_FOR_REMOVABLE_PROSTHESIS ||
    productType === ItemType.WAX_UP
  );
};

export const getArchFromTeeth = (
  teeths: number[],
  shouldTranslate: boolean = false,
): string => {
  if (!teeths || teeths.length === 0) {
    return "";
  }
  const onlyMaxilaire = teeths.every(teeth => teeth <= 28);
  const onlyMandibulaire = teeths.every(teeth => teeth >= 31);
  if (onlyMaxilaire && !onlyMandibulaire) {
    return shouldTranslate ? ArchTypeTranslated.MAXILLAIRE.toString() : ArchType.MAXILLAIRE.toString();
  }
  if (onlyMandibulaire && !onlyMaxilaire) {
    return shouldTranslate ? ArchTypeTranslated.MANDIBULAIRE.toString() : ArchType.MANDIBULAIRE.toString();
  }
  return shouldTranslate ? ArchTypeTranslated.BIMAXILAIRE.toString() : ArchType.BIMAXILAIRE.toString();
};

export const getProductTeeth = (product: Product): number[] => {
  const productType = product?.productType;
  if (
    productType === ItemType.BRIDGE_IMPLANT ||
    productType === ItemType.IMPLANT ||
    productType === ItemType.PARTIAL_DENTURE
  ) {
    return product.teeth;
  } else if (
    isAnatomyItemType(productType) &&
    product.anatomyItem &&
    product.anatomyItem.length > 0 &&
    product.anatomyItem[0].teeth
  ) {
    return product.teeth ? (product.teeth as number[]) : [];
  } else if (
    isImplantItemType(productType) &&
    product.implantItem &&
    product.implantItem.length > 0 &&
    product.implantItem[0].teeth
  ) {
    return product.implantItem[0].teeth;
  } else if (
    isRemovableItemType(productType) &&
    product.removableItem &&
    product.removableItem.length > 0 &&
    product.removableItem[0].teeth
  ) {
    return product.removableItem[0].teeth;
  }
  return [];
};

export const getTeethLabel = (
  itemType: ItemType,
  teeth: number[],
  teethToManufacture?: number[],
  shouldTranslate: boolean = false,
): string => {
  if (!isArrayOfInt(teeth)) return "";
  const isWithArch = isRemovableItemType(itemType);
  let teethLabel = "";
  if (teeth.length <= 0) {
    return "non définies";
  }
  if (isWithArch === false) {
    teethLabel = teeth.length > 1 ? "dents " : "dent ";
    [...(teeth as number[])].sort((a, b) => a - b).forEach(tooth => {
      if (!isNaN(tooth)) {
        teethLabel += tooth + " ";
      }
    });
    if (teethLabel) {
      teethLabel = teethLabel.slice(0, -1);
    }
  } else {
    if (
      itemType !== ItemType.FULL_DENTURE
      && itemType !== ItemType.FULL_DENTURE_WAX_BITE
      && teethToManufacture
      && teethToManufacture.length > 0
    ) {
      teeth = teethToManufacture ?? teeth;
    }
    if (itemType === ItemType.PARTIAL_DENTURE){
      return `${getArchFromTeeth(teeth, shouldTranslate)}: ${teeth.join(", ")}`;
    }
    return getArchFromTeeth(teeth, shouldTranslate);
  }
  if (itemType === ItemType.BRIDGE_IMPLANT) {
    return teeth.join(" ");
  }
  return teethLabel;
};

export const getAllTeeths = (): number[] => {
  const arr = [];
  for (let i = 11; i <= 18; i++) {
    arr.push(i);
  }
  for (let i = 21; i <= 28; i++) {
    arr.push(i);
  }
  for (let i = 31; i <= 38; i++) {
    arr.push(i);
  }
  for (let i = 41; i <= 48; i++) {
    arr.push(i);
  }
  return arr;
};

export const findUserPreference = (
  userId: string,
  productPreferences: { dentist: { id: string }, productPreferenceType: ProductPreferenceType }[]
) => {
  return ((productPreferences ?? []).find((preference) => preference?.dentist?.id === userId && preference?.productPreferenceType === ProductPreferenceType.FIXED_PROSTHESIS_PREFERENCES));
}

export const findUserPreferenceForRemovablePreference = (
  userId: string,
  productPreferences: { dentist: { id: string }, productPreferenceType: ProductPreferenceType }[]
) => {
  return ((productPreferences ?? []).find((preference) => preference?.dentist?.id === userId && preference?.productPreferenceType === ProductPreferenceType.REMOVABLE_PROSTHESIS_PREFERENCES));
}
